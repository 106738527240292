@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 46.1%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 10% 3.9%;
        --radius: 0.5rem;
    }
    .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;
        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #3d3e3e;
    background-color: #fff;
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

@page {
    margin: 15mm 10mm 15mm 10mm;
}

.filterDateVente {
    z-index: 600 !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

/* .react-datepicker__day:not(.react-datepicker__day--disabled) {
    background-color: #db291b !important;
    color: #fff !important;
    border-radius: 0.3rem !important;
} */



#agendaSideBarContainer .react-datepicker
{
	width: 100% !important;
	border: none;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__month-container
{
	float: none !important;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__month-container .react-datepicker__header
{
	background-color: #db291b;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__month-container .react-datepicker__header
{
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month,
#agendaSideBarContainer .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name
{
	color: #fff !important;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--previous::before,
#agendaSideBarContainer .react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--next::before
{
	border-color: #fff !important;
}
.hasAvailable .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled),
#agendaSideBarContainer .react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled)
{
	background-color:#db291b !important;
	color: #fff !important	;
	border-radius:0.3rem !important;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__day--today
{
	color: #db291b !important;
    font-weight: normal;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__day--selected
{
    font-weight: bold;
    font-size: larger;
}
#agendaSideBarContainer .react-datepicker .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
{
	width: 2.3rem !important;
    line-height: 2.3rem !important;
}
